@import 'styles/color.scss';
@import 'styles/mixin.scss';

@mixin user_group_area {
  border-radius: .25rem;
  border: solid 1px;
  transition: all 300ms ease;
  height: 2rem;
  margin: .25rem 0;
  padding: 0.25rem;
  line-height: 1em;
}
.activeUserGroupArea {
  @include user_group_area;
  background: white;
  @include focus_border;
}
.inActiveUserGroupArea {
  @include user_group_area;
  opacity: .7;
  background: white;
  border-color: $alto; //$cadet-blue;
}