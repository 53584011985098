@import 'styles/color.scss';
.logo {
  background: rgba(255, 255, 255, 0.2);
  margin: 1rem;
  color: white;
  text-align: center;
  padding: 0.5rem;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #1890ff;
  }
}

.version {
  position: absolute;
  bottom: 0.25rem;
  left: 0.5rem;
  color: $lynch;
}

.header {
  background: white;
  padding: 0px;
  z-index: 10;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
}

.sider {
  min-height: 100vh;
  background: $menu-bg;
}

.main {
  max-height: 100vh;
}

.affix {
  position: fixed;
  right: 0px;
  bottom: 44px;
}

.closeButton {
  cursor: pointer;
  width: 28px;
  height: 28px;
  position: relative;
  left: 716px;
  top: 88px;
}

.bottomCloseButton {
  cursor: pointer;
  width: 320px;
  height: 50px;
  position: relative;
  left: 296px;
  bottom: -386px;
}

.bold {
  font-weight: 600;
}
