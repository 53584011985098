@import "./font.scss";
@import "./pxtorem.scss";
@import "./color.scss";

@mixin bigTitle {
  @include FontCN;
  font-weight: normal;
  font-size: rem(18);
  color: $steel-gray;
}
@mixin smallTitle {
  @include FontCN;
  font-weight: normal;
  font-size: rem(16);
  color: $gun-powder;
}
@mixin cardTitle {
  @include FontCN;
  font-weight: bold;
  font-size: rem(16);
  color: $gun-powder;
}
@mixin tableHead {
  @include FontCN;
  font-weight: bold;
  font-size: rem(14);
  color: $gun-powder;
}
@mixin tableName {
  @include FontCN;
  font-weight: normal;
  font-size: rem(14);
  color: $gun-powder;
}
@mixin cardDescription {
  @include FontCN;
  font-weight: normal;
  font-size: rem(14);
  color: $white-lilac;
}
@mixin selectedTab {
  @include FontCN;
  font-weight: normal;
  font-size: rem(14);
  color: $dodger-blue;
}
@mixin unselectedTab {
  @include FontCN;
  font-weight: normal;
  font-size: rem(14);
  color: $cadet-blue;
}
@mixin content {
  @include FontCN;
  font-weight: normal;
  font-size: rem(14);
  color: $lynch;
}
@mixin description {
  @include FontCN;
  font-weight: normal;
  font-size: rem(12);
  color: $lynch;
  line-height: 1.25em;
}
@mixin N1 {
  @include FontNum;
  font-size: rem(24);
  color: $dodger-blue;
}
@mixin N2 {
  @include FontNum;
  font-size: rem(24);
  color: $gun-powder;
}
@mixin N3 {
  @include FontNum;
  font-size: rem(20);
  color: $dodger-blue;
}
@mixin N4 {
  @include FontNum;
  font-size: rem(18);
  color: $gun-powder;
}
@mixin N5 {
  @include FontNum;
  font-size: rem(16);
  color: $lynch;
}
@mixin N6 {
  @include FontNum;
  font-size: rem(16);
  color: $dodger-blue;
}
