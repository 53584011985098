@import 'styles/typography.scss';

.state {
  position: absolute;
  right: 0;
  top: 0;
}
.title {
  @include smallTitle;
  margin-right: 48px;

  &,
  & > a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
