.header {
  background-color: #007FF6;
  background-image: url("/images/crown.png");
  background-repeat: no-repeat;
  background-size: 4.25rem;
  background-position: right bottom;
  height: max-content;
  display: block;
  padding-bottom: .8rem;
}
$logo-size: 2.85rem;
.left {
  display: inline-block;
  min-height: $logo-size + 0.8rem;
  width: $logo-size + 1.5rem;
  background-image: url("/images/logo.svg");
  background-repeat: no-repeat;
  background-size: $logo-size;
  background-position: bottom right;
}
.right {
  display: inline-block;
  vertical-align: top;
  padding-left: 0.3125rem;
}
.logo {
  height: $logo-size - 0.5rem;
  width: $logo-size;
  background-image: url("/images/wk.png");
  background-repeat: no-repeat;
  background-size: $logo-size;
  background-position: left bottom;
}
.shopTitle {
  padding-top: 0.25rem;
  max-width: 8em;
  color: white;
}
