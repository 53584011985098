@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Medium.ttf") format("truetype"), 
    url("/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("/fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Regular.ttf") format("truetype"), 
    url("/fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("/fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@mixin FontCN {
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
}

@mixin FontNum {
  font-family: tahoma,"lucida grande", "lucida sans unicode"
  // font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
  //   "Helvetica Neue", sans-serif;
}