$steel: #666666;
$steel-gray: #434349;
$gun-powder: #464457;
$lynch: #6c7293;
$cadet-blue: #a7abc3;
$alto: #d9d9d9;
$catskill-white: #f2f5f8;
$light-blue: #e6f7ff;
$columbia-blue: #91d5ff;
$picton-blue: #40A9FF;
$dodger-blue: #178FFE;
$ultramarine-blue: #5d78ff;
$melrose: #bec8fb;
$white-lilac: #E8E8E8;
$menu-bg: #0070D8;
$menu-bg-sub: #005FB7;
$menu-selected: #44A5FF;