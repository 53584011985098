@import 'styles/typography.scss';

.toList {
  font-size: 12px;
  color: #84868c;
}

.planCardListContainer {
  display: flex;
  flex-wrap: wrap;
}

.planCardContainer {
  position: relative;
}

.mb12 {
  margin-bottom: 12px;
}
