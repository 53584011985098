@import 'styles/typography.scss';

.container {
  border-radius: 8px;
  background-color: #fff;
  padding: 0;
  height: 180px;
}
.titleImage {
  position: absolute;
  right: 16px;
  top: 0px;
  z-index: 100;
}
.title {
  margin: 0;
  position: relative;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  &,
  & > a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #232426;
  }
}
.body {
  height: 132px;
  margin: 0 !important;
}
.customModeContent {
  height: 100%;
  padding: 0 !important;
}
.customModeLeftContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftPicture {
  margin-right: 10px;
}
.leftTextTitle {
  font-size: 12px;
  color: #84868c;
}
.leftTextNumberContainer {
  display: flex;
  align-items: center;
}
.leftUlContainer {
  border-left: 1px dashed #ddd;
}
.planUl {
  padding-left: 19px;
  margin-bottom: 0;
}
.planLiText {
  color: #84868c;
  font-size: 14px;
  margin-bottom: 12px;
}
.autoModeContent {
  text-align: center;
  margin-top: 40px;
}
.autoItem {
  border-right: 1px dashed #ddd;
  padding: 0;
}
