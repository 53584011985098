@import 'styles/typography.scss';
@import 'styles/color.scss';

.cardCountTitle {
  @include content;
  color: rgba(0, 0, 0, .85);
}

.cardCountNumber {
  @include N6;
}

.cardCountSuffix {
  @include content;
  color: rgba(0, 0, 0, .6);
}