@import "./font.scss";
@import "./color.scss";
@import "styles/font.scss";

body {
  margin: 0;
  padding: 0;
  // global font PingFang SC
  @include FontCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * overwrite antd 
 */

// Statistic number font to Poppins
.ant-statistic-content-value-int {
  @include FontNum;
}

// Layout
.ant-layout {
  background: $catskill-white;
}

// Table tbody
.ant-table-tbody {
  background: white;
}

// rmb style
.ant-statistic-content-prefix span {
  @include FontCN;
}

tr.ant-table-expanded-row {
  background: white;
}

.ant-tooltip {
  &-arrow {
    &::before {
      background-color: white;
    }
  }
  &-inner {
    background: white;
    color: #666666;
  }
}

.left-table {
  table {
    thead {
      .ant-table-selection-column {
        .ant-table-header-column {
          visibility: hidden;
        }
      }
    }
  }
}

.ant-menu {
  background-color: $menu-bg;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
  margin-top: 0;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: $menu-bg-sub;
  box-shadow: none;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: $menu-selected;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: white;
  & > a {
    color: $menu-bg;
  }
}
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: $menu-bg;
}
